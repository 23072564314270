import React from 'react'
import './about.css'
import ME from '../../assets/aboutme2copy.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">

          <p>
          I'm passionate about crafting digital experiences where creativity meets technology. I've journeyed from a graphic and web designer to a seasoned front-end developer in e-commerce, building a robust track record over the past 8 years.  This path naturally guided me to the role of Product Manager. <br /><br />

With a diverse skill set, I seamlessly connect business teams to digital experiences and platforms through clear, empathetic communication and easily comprehensible technologies. Join me on this journey where every click, pixel, and line of code tells a story of innovation and success. Let's create impactful digital experiences together!
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About