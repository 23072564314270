import React from 'react'
import './testimonials.css'

// import Swiper core and required modules
import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules




const data = [
  {
    name: 'Kavya',
    review: "I had the privilege of working with Maja in the product management space for a few months at Gap Inc. and I have been consistently impressed by Maja's professionalism, dedication, curiosity and collaboration skills to deliver features in a complex product construct. Maja's positive outlook/energy to solve problems is a breath of fresh air but most importantly, her technical expertise coupled with the ability to connect dots with business value makes her an invaluable product manager for any team! "
  },
  {
    name: 'Rob',
    review: "Maja is brilliant. During my time as her manager I was continually impressed, and let’s be frank here, sometimes humbled at her absolutely fierce dedication not only to the tasks that she was assigned, but never backing down from a challenge in her work. If the initial answer was “that may not work” it was always followed by a potential path to ‘…but we can try this instead.” She’ll research, do technical explores, and work 1:1 with members of our creative team to make sure that whatever the project, it meets the brand’s vision. She’s among the best I’ve ever worked with."
  },
  {
    name: 'Corry',
    review: "Maja is a star who can't help but shine among her peers. She's never content to do the bare minimum, always striving to help out any way she can to push any project she's on forward. She's a true team player who can see the big picture and the small details. She learns fast on the fly and never hesitates to share her knowledge. Any team is lucky to have her on it."
  },
  {
    name: 'Stefanie',
    review: "Maja has been an instrumental asset to Gap brand, more specifically to gap.com home page and personalization during the past 2+ years with Gap Inc. Maja’s been my go to girl for all backend Web Content Development needs across site refreshes and is extremely knowledgeable within personalization segmentation and Certona capabilities. Her keen eye for detail and ability to multitask between multiple projects really stood out to me the most when working cross functionally. Maja always had a can do attitude when presented with a UX challenge and functionality during Slice and Pass and her ability to troubleshoot was never left unseen. No matter where Maja ends up, any company would be lucky to have her on their team."
  },
  {
    name: 'Stacy',
    review: "I am pleased to recommend Maja for any web development position. As a Senior Web Developer for Gap Online, Maja helped produce high-quality marketing content for several years. She was an excellent team player and collaborated, closely working with the production team. Maja's technical expertise, problem-solving, and commitment to producing outstanding work made her a valuable asset to our organization and team. I am confident that Maja will continue to excel in her career as a web developer and highly recommend her for any future employment opportunities."
  },
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Colleagues References</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container" 
      // install Swiper modules
      modules={[Pagination]} spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({ name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials