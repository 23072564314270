import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Product Managment</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Implementing Agile methodologies for efficient and iterative product development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Crafting product strategies aligned with the the core business objectives</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Defining and prioritizing product features based on user needs and market trends</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Conducting in-depth user research to understand preferences and pain points</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Fostering seamless communication for effective collaboration across teams</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Enhancing user experiences through empathetic design</p>
            </li>
          </ul>
        </article>
        {/* END OF Product Managment */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Crafting unique and visually appealing website designs tailored to your brand</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Implementing responsive and user-friendly interfaces for seamless user experiences</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Implementing user-friendly CMS for easy content updates and management</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Optimizing website speed and performance for enhanced user satisfaction.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Creating websites with a mobile-first approach for optimal performance on various devices</p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Conducting in-depth research to understand user needs, behaviors, and preferences</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Developing interactive prototypes to visualize and test the user experience</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Organizing and structuring content to enhance user understanding and navigation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Ensuring a consistent and optimal user experience across various devices and screen sizes</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Implementing design elements to make digital products accessible to users with diverse needs</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Conducting tests to evaluate the usability and effectiveness of the design with real users</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services